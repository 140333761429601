import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import settings from 'config/settings';
import { COUNT_TABLE_RECORDS } from 'constants/constants';

const { rootUrl } = settings;

export const GET_PROJECT_PDF_REQUESTS = gql`
  query getProjectPDFRequests($limit: Int!, $offset: Int!, $filter: String, $rootUrl: String, $locale: String) {
    projectPDFRequests(limit: $limit, offset: $offset, filter: $filter) {
      count
      items {
        _id
        created_at
        project {
          _id
          name
          pdf(locale: $locale, url: $rootUrl)
        }
        meta {
          clientIP
        }
        user {
          email
          firstName
          lastName
        }
      }
    }
  }
`;

const useGetProjectPDFRequests = ({ currentPage, filter }) => {
  const { locale } = useIntl();
  const { data, loading, error } = useQuery(GET_PROJECT_PDF_REQUESTS, {
    variables: {
      locale,
      rootUrl,
      filter,
      offset: COUNT_TABLE_RECORDS * (currentPage - 1),
      limit: COUNT_TABLE_RECORDS,
    },
  });

  const projectPDFRequests = get(data, 'projectPDFRequests.items', []);
  const total = get(data, 'projectPDFRequests.count', 0);

  return { projectPDFRequests, total, loading, error };
};

export default useGetProjectPDFRequests;
