import React, { useCallback } from 'react';
import { CCard, CCardHeader, CCol, CFormGroup, CLabel, CInput, CCardBody } from '@coreui/react';
import useGetProjects from 'hooks/projects/useGetProjects';
import ProjectsTable from 'components/projects/ProjectsTable';
import { useIntl } from 'react-intl';
import usePagination from 'hooks/usePagination';
import useFilterValue from 'hooks/useFilterValue';
import Button from 'components/common/Button';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import settings from 'config/settings';
import DatePicker from 'components/inputs/DatePicker';
import { endOfDay, startOfYear } from 'date-fns';
import { FastField, Form, Formik } from 'formik';
import s from './Projects.module.scss';

const ReportForm = () => {
  const { formatMessage: t } = useIntl();
  const onSubmit = useCallback(() => {}, []);
  const initialValues = {
    dateRange: [startOfYear(new Date()), endOfDay(new Date())],
  };

  return (
    <Formik {...{ initialValues, onSubmit }}>
      {({ values }) => (
        <Form style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '16px', flexWrap: 'wrap' }}>
          <FastField
            name="dateRange"
            label="projects.date_range"
            component={DatePicker}
            selectsRange
            isClearable
            startDate={initialValues.dateRange[0]}
            endDate={initialValues.dateRange[1]}
          />

          <a
            href={`${settings.xlsxProjectsReportUrl}${
              values.dateRange[0] && values.dateRange[1]
                ? `?startDate=${values.dateRange[0]}&endDate=${values.dateRange[1]}`
                : ''
            }`}
            onClick={(e) => e.stopPropagation()}
          >
            <Button style={{ marginTop: '8px' }} color="primary">
              {t({ id: 'projects.xlsx_export' })}
            </Button>
          </a>
        </Form>
      )}
    </Formik>
  );
};

export default function Projects() {
  const { formatMessage: t } = useIntl();
  const { currentPage, changePage } = usePagination({ nameOfRoute: 'projects' });
  const { handleChange, debouncedFilterValue, filter } = useFilterValue({ changePage });
  const { projects, total, loading, error } = useGetProjects({ currentPage, filter: debouncedFilterValue });

  return (
    <CCol>
      <CCard>
        <CCardHeader className={s.header}>
          <h4>{t({ id: 'page_titles.projects' })}</h4>
          <ReportForm />
        </CCardHeader>
        <CCardBody>
          {error ? (
            <AlertGraphQLError error={error} />
          ) : (
            <>
              <CFormGroup>
                <CLabel htmlFor="filter">{t({ id: 'projects.filter' })}</CLabel>
                <CInput id="filter" type="search" name="filter" value={filter} onChange={handleChange} />
              </CFormGroup>
              <ProjectsTable {...{ projects, total, currentPage, changePage, loading }} />
            </>
          )}
        </CCardBody>
      </CCard>
    </CCol>
  );
}
