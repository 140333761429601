import { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';

const usePagination = ({ nameOfRoute }) => {
  const { page } = useParams();
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);
  const history = useHistory();

  const handleChangePage = useCallback(
    debounce((_page) => {
      setCurrentPage(_page);
      window.scroll({ top: 0, behavior: 'smooth' });
      if (routePatterns[nameOfRoute]) {
        history.push(routePatterns[nameOfRoute].stringify({ page: _page }));
      }
    }, 200),
    [history],
  );

  return { changePage: handleChangePage, currentPage };
};

export default usePagination;
