import React from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CFormGroup, CInput, CLabel } from '@coreui/react';
import { useIntl } from 'react-intl';
import useGetHypoNoeApiRequests from 'hooks/hypoNoeApiRequests/useGetHypoNoeApiRequestsReport';
import { useModal } from 'components/common/Modal';
import usePagination from 'hooks/usePagination';
import useFilterValue from 'hooks/useFilterValue';
import APIRequestsTable from 'components/hypoNoeApiRequestsTable/HypoNoeApiRequestsTable';
import APIRequestDetailsModal from 'components/hypoNoeApiRequestsTable/APIRequestDetailsModal';
import AlertGraphQLError from 'components/common/AlertGraphQLError';

const HypoNoeApiRequestsReport = () => {
  const { formatMessage: t } = useIntl();
  const { showModal, hideModal, modalProps, isVisible } = useModal();
  const { currentPage, changePage } = usePagination({ nameOfRoute: 'hypoNoeApiRequestsReport' });
  const { handleChange, debouncedFilterValue, filter } = useFilterValue({ changePage });
  const { requests, total, loading, error } = useGetHypoNoeApiRequests({ currentPage, filter: debouncedFilterValue });

  return (
    <CCol>
      <CCard>
        <CCardHeader>
          <h4>{t({ id: 'page_titles.hypo_noe_api_requests_report' })}</h4>
        </CCardHeader>

        <CCardBody>
          {error ? (
            <AlertGraphQLError error={error} />
          ) : (
            <>
              <CFormGroup>
                <CLabel htmlFor="filter">{t({ id: 'projects.filter' })}</CLabel>
                <CInput id="filter" type="search" name="filter" value={filter} onChange={handleChange} />
              </CFormGroup>
              <APIRequestsTable {...{ t, showModal, requests, total, changePage, currentPage, loading }} />
              <APIRequestDetailsModal {...{ requestId: modalProps.requestId, hideModal, isVisible }} />
            </>
          )}
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default HypoNoeApiRequestsReport;
