/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useMemo, useState } from 'react';
import RDatePicker, { registerLocale } from 'react-datepicker';
import { get } from 'lodash';
import de from 'date-fns/locale/de';
import { useIntl } from 'react-intl';
import { CFormGroup, CInvalidFeedback, CLabel } from '@coreui/react';
import 'react-datepicker/dist/react-datepicker.css';
import { sub } from 'date-fns';

registerLocale('de', de);

const DatePicker = ({
  field,
  form,
  label,
  dateFormat = 'dd.MM.yyyy',
  showYearPicker,
  yearItemNumber,
  isClearable,
  maxDate,
  startDate: _startDate,
  endDate: _endDate,
  selectsRange,
}) => {
  const { name } = field;
  const { touched, errors } = form;
  const { formatMessage: t, locale } = useIntl();
  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);
  const [dateRange, setDateRange] = useState([
    _startDate ? new Date(_startDate) : null,
    _endDate ? new Date(_endDate) : null,
  ]);
  const [startDate, endDate] = dateRange;

  const subTimezoneOffset = useCallback((date) => sub(date, { minutes: new Date(date).getTimezoneOffset() }), []);

  const handleChange = useCallback(
    (date) => {
      if (selectsRange) {
        let d = date;
        if (date[0] && date[1]) {
          d = [subTimezoneOffset(date[0]), subTimezoneOffset(date[1])];
        }
        setDateRange(d);
        form.setFieldValue(name, d);
      } else {
        const d = date ? subTimezoneOffset(date) : undefined;
        form.setFieldValue(name, d);
      }
    },
    [form, name, selectsRange, subTimezoneOffset],
  );

  return (
    <CFormGroup>
      <CLabel htmlFor={name} className="text-primary">
        {t({ id: label })}
      </CLabel>
      <div style={{ minWidth: '240px' }}>
        <RDatePicker
          {...{ name, dateFormat, showYearPicker, yearItemNumber, isClearable, startDate, endDate, selectsRange }}
          locale={locale}
          autoComplete="off"
          maxDate={maxDate}
          showYearDropdown
          showMonthDropdown
          {...(selectsRange ? {} : { selected: field.value ? new Date(field.value) : undefined })}
          onChange={handleChange}
          className="form-control w-100"
        />
      </div>
      {error ? <CInvalidFeedback className="help-block">{errors[name]}</CInvalidFeedback> : null}
    </CFormGroup>
  );
};

export default DatePicker;
